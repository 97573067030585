import * as React from 'react';
import Layout from '../layouts/Layout';

import { Container } from '@material-ui/core';
import YoutubeVideo from '../components/Shared/YoutubeVideo/YoutubeVideo';

const AtHome = () => {
  return (
    <Layout title="At Home">
      <div style={{ marginTop: 80 }} />
      <Container>
        <YoutubeVideo videoUrl="https://www.youtube.com/embed/YtldOhYii-I" />
      </Container>
    </Layout>
  );
};

export default AtHome;
